<template>
  <v-form v-if="typeof userDataVal !== 'undefined'" @submit="handleSubmit">
    <v-container :fluid="true">
      <v-row class="user-profile-container mb-15">
        <UserProfilePictureLoader :edit-mode="true" />

        <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="user-profile-form ml-12">
          <v-row>
            <v-col class="mb-3">
              <v-row>
                <v-col cols="12" md="6" xl="4">
                  <v-text-field
                    v-model="firstName"
                    :rules="[rules.required, rules.isAlphabeticOnly]"
                    :label="t('i18n_first_name')"
                    variant="outlined"
                    bg-color="#ffff"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6" xl="4">
                  <v-text-field
                    v-model="lastName"
                    :rules="[rules.required, rules.isAlphabeticOnly]"
                    :label="t('i18n_last_name')"
                    variant="outlined"
                    bg-color="#ffff"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="mb-6">
              <v-row>
                <v-col cols="12" md="6" lg="6" xl="4">
                  <v-text-field
                    v-model="phoneNumber"
                    :label="t('i18n_phone_number')"
                    variant="outlined"
                    bg-color="#ffff"
                    maxlength="14"
                    @input="handlePhoneNumber"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="d-none d-md-block" offset-md="3">
                  <a class="d-block profile-link" href="javascript://" @click="handleLogout">
                    {{ t('i18n_logout') }}
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row>
                <v-col cols="12" md="6" lg="6" xl="4">
                  <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    :label="t('i18n_email_address')"
                    variant="outlined"
                    bg-color="#ffff"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <h4 class="user-profile-form__label">{{ t('i18n_password') }}</h4>
                  <a class="profile-link" href="javascript://" @click="showPasswordDialog">
                    {{ t('i18n_change_password') }}
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="user-profile-confirm-container user-profile-confirm-container--edit">
        <v-col
          cols="12"
          sm="12"
          md="4"
          offset-md="1"
          lg="3"
          offsset-lg="3"
          xl="3"
          offset-xl="3"
          xxl="3"
        >
          <v-btn
            type="submit"
            rounded="xl"
            class="user-profile-confirm-container__btn-confirm"
            :flat="true"
            :disabled="isFormInvalid"
          >
            {{ t('i18n_save_settings') }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="d-md-none d-sm-block">
          <a class="d-block profile-link" href="javascript://" @click="handleLogout">
            {{ t('i18n_logout') }}
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
import {
  useI18n,
  isAlphabeticOnly,
  isValidEmail,
  formatPhoneNumber,
  IconType,
  MessageType
} from 'arly-common'
import { useAuth0, type Auth0VueClient } from '@auth0/auth0-vue'
import { computed, onBeforeMount, ref, inject } from 'vue'
import { logoutUser, updateUserData } from '@/services/UserService'
import UserProfilePictureLoader from './UserProfilePictureLoader.vue'
import { useUserStore } from '@/stores/UserStore'
import type { IUserProfileToast } from '@/models/UserProfileToast'
import { storeToRefs } from 'pinia'

const auth0: Auth0VueClient = useAuth0()
const { t } = useI18n()
const userStore = useUserStore()
const { userDataVal } = storeToRefs(userStore)
const emit = defineEmits<{
  (e: 'setEditMode', active: boolean): void
  (e: 'showPasswordReset'): void
}>()
const firstName = ref('')
const lastName = ref('')
const phoneNumber = ref('')
const email = ref('')
const handleToastMessage =
  inject<({ toastProps, toastColorValue }: IUserProfileToast) => void>('handleToastMessage')

if (userDataVal.value) {
  firstName.value = userDataVal.value.firstName
  lastName.value = userDataVal.value.lastName
  phoneNumber.value = userDataVal.value.phoneNumber
  email.value = userDataVal.value.email
}

onBeforeMount(() => {
  handlePhoneNumber(phoneNumber.value)
})

const rules = {
  required: (value: string): string | boolean => !!value || t('i18n_field_is_required'),
  isAlphabeticOnly: (value: string): string | boolean => {
    return isAlphabeticOnly(value) || t('i18n_only_alphabetic_characters')
  },
  email: (value: string): string | boolean => {
    return isValidEmail(value) || t('i18n_invalid_email')
  }
}

const isFormInvalid = computed(() => {
  return (
    firstName.value === '' ||
    !firstName.value ||
    !isAlphabeticOnly(firstName.value) ||
    lastName.value === '' ||
    !lastName.value ||
    !isAlphabeticOnly(lastName.value) ||
    email.value === '' ||
    !email.value ||
    !isValidEmail(email.value)
  )
})

function handlePhoneNumber(targetValue: InputEvent | string): void {
  const inputValue =
    typeof targetValue === 'string' ? targetValue : (targetValue.target as HTMLInputElement).value
  const numericValue = inputValue.replace(/\D/g, '')
  phoneNumber.value = formatPhoneNumber(numericValue)
}

function handleLogout(): void {
  logoutUser(auth0)
}

function handleSubmit(e: Event): void {
  e.preventDefault()
  if (userDataVal.value) {
    const userPayload = {
      ...userDataVal.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phoneNumber: phoneNumber.value.replace(/\D/g, '')
    }

    ;(async (): Promise<void> => {
      try {
        await updateUserData(userPayload)
        userStore.setUserData(userPayload)

        handleToastMessage?.({
          toastColorValue: MessageType.success,
          toastProps: {
            icon: IconType.success,
            message: t('i18n_user_profile_saved'),
            title: t('i18n_profile')
          }
        })

        emit('setEditMode', false)
      } catch (error) {
        handleToastMessage?.({
          toastColorValue: MessageType.error,
          toastProps: {
            icon: IconType.error,
            message: t('i18n_user_profile_saving_error'),
            title: t('i18n_profile')
          }
        })

        throw new Error('Error during user account update')
      }
    })()
  }
}

function showPasswordDialog(): void {
  emit('showPasswordReset')
}
</script>

<style lang="scss" scoped src="@/components/user-profile/UserProfileComponent.scss"></style>
