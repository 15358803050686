import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import NotFound from '@/views/NotFound.vue'
import RegisterView from '@/views/RegisterView.vue'
import UserProfileComponent from '@/components/user-profile/UserProfileComponent.vue'
import UserProfileViewVue from '@/views/UserProfileView.vue'
import PlayGroundView from '@/views/PlaygroundView.vue'
import { PLAYGROUND_FLAG } from '@/constants/featureflags'
import launchDarklyGuard from '@/router/guards/launchDarklyGuard'
import { authGuard } from '@auth0/auth0-vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      beforeEnter: authGuard,
      meta: { name: 'home', gtm: 'Home' }
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterView,
      meta: { name: 'Register', gtm: 'Register' }
    },
    {
      path: '/user',
      component: UserProfileViewVue,
      beforeEnter: authGuard,
      children: [
        {
          name: 'profile',
          path: 'profile',
          component: UserProfileComponent,
          props: {
            subHeaderTitle: 'PROFILE',
            returnTo: '',
            path: '/user/profile',
            subHeader: true,
            bgColor: 'blue-theme'
          },
          meta: { name: 'My Profile', gtm: 'Profile' }
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: NotFound
    },
    {
      path: '/playground',
      name: 'playground',
      component: PlayGroundView,
      beforeEnter: launchDarklyGuard(PLAYGROUND_FLAG, null, false),
      meta: { name: 'playground', gtm: 'Playground' }
    }
  ]
})

export default router
