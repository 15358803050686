<template>
  <header>
    <nav><HeaderComponent :page-title="t('i18n_not_found')" /></nav>
  </header>

  <FooterComponent />
</template>

<script setup lang="ts">
import { useI18n, HeaderComponent } from 'arly-common'
import FooterComponent from '@/components/footer/FooterComponent.vue'

const { t } = useI18n()
</script>
