<template>
  <header>
    <nav>
      <HeaderComponent
        v-if="userRole"
        :user-role-data="userRole"
        :page-title="(currentRouteName as string)"
        :username="userStore.userDataVal?.firstName"
        :constant-envs="ENV_REFERENCES"
        @handle-menu-route="handleMenuRoute"
      />
    </nav>
  </header>

  <UserProfileComponent v-if="userStore.userDataVal" />

  <FooterComponent />
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { useAuth0, type Auth0VueClient } from '@auth0/auth0-vue'
import { useUserStore } from '@/stores/UserStore'
import {
  HeaderComponent,
  type IUser,
  type IUserRole,
  type INavigationParam,
  type IEnvReference,
  UserRoleEnum,
  HELP_CENTER_ID
} from 'arly-common'
import UserProfileComponent from '@/components/user-profile/UserProfileComponent.vue'
import FooterComponent from '@/components/footer/FooterComponent.vue'
import { getUserInfo, getMSUrl, logoutUser } from '@/services/UserService'

const auth0: Auth0VueClient = useAuth0()
const route = useRoute()
const currentRouteName = computed(() => route.name)
const userStore = useUserStore()
const userRole = ref<IUserRole>()

const ENV_REFERENCES: IEnvReference = {
  VITE_API_BASE_ADMIN_URL: import.meta.env.VITE_API_BASE_ADMIN_URL,
  VITE_API_BASE_CMS_URL: import.meta.env.VITE_API_BASE_CMS_URL,
  VITE_API_BASE_ENROLLMENT_URL: import.meta.env.VITE_API_BASE_CMS_URL,
  VITE_API_BASE_FAMILY_URL: import.meta.env.VITE_API_BASE_FAMILY_URL,
  VITE_API_BASE_IDENTITY_URL: import.meta.env.VITE_API_BASE_IDENTITY_URL,
  VITE_APP_BASE_ADMIN_REDIRECT_URL: import.meta.env.VITE_APP_BASE_ADMIN_REDIRECT_URL,
  VITE_APP_BASE_IDENTITY_REDIRECT_URL: import.meta.env.VITE_APP_BASE_IDENTITY_REDIRECT_URL,
  VITE_APP_BASE_ENROLLMENT_REDIRECT_URL: import.meta.env.VITE_APP_BASE_ENROLLMENT_REDIRECT_URL
}

onBeforeMount(async () => {
  const userAccount: IUser = await getUserInfo()
  const { userRoles } = userAccount
  const highestRole = Math.min(...userRoles.map((role: IUserRole) => role.roleId))
  const highestRoleData = userRoles.find((el: IUserRole) => el.roleId === highestRole)

  //TODO: this logic should be checked with the role documents
  // if (highestRoleData) {
  //   highestRoleData.roleId = highestRole <= 4 ? highestRole : 0
  // }

  userRole.value =
    highestRole <= UserRoleEnum.bxContentAdmin
      ? userRoles[userRoles.length - 1]
      : highestRoleData ?? undefined
  userStore.setUserData(userAccount)
})

async function handleMenuRoute(redirectionParam: INavigationParam): Promise<void> {
  switch (redirectionParam.callbackType) {
    case 'logout':
      logoutUser(auth0)
      break
    case 'navigateUrl': {
      const targetUrl = await getMSUrl(redirectionParam.optionId || HELP_CENTER_ID)
      window.open(targetUrl, '_blank')
      break
    }
    default: {
      const [domainEnv, ...targetPath] = redirectionParam.url.split('/')
      const finalEnvDomain =
        ENV_REFERENCES[domainEnv].indexOf('/') === -1
          ? ENV_REFERENCES[domainEnv].concat('/')
          : ENV_REFERENCES[domainEnv]

      window.location.href = finalEnvDomain.concat(targetPath.join('/'))
    }
  }
}
</script>
