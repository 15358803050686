<template>
  <div class="footer-main-container">
    <img
      :src="footerSwoop"
      :class="smAndDown ? 'footer-swoop__mobile' : xs ? 'footer-swoop__mobile-xs' : 'footer-swoop'"
      alt=""
    />

    <footer id="footer">
      <v-container class="footer" width="100%">
        <v-row align="center" no-gutters>
          <v-col lg="2" md="2" sm="2" cols="12">
            <img :src="footerLogo" alt="Arly Logo" />
          </v-col>

          <v-col class="px-5 py-5" align-self="end" lg="10" md="10" sm="9" cols="12">
            <v-row justify="space-between">
              <hr class="footer__divider" />

              <v-col class="footer__col-start" cols="3" sm="6">
                <span class="footer__item">© {{ currentYear }} {{ t('all_rights_reserved') }}</span>
              </v-col>
              <v-col class="footer__col-end" cols="3">
                <span class="footer__item"
                  ><a :href="PRIVACY_POLICY_URL" class="footer-link" target="_blank">{{
                    t('privacy_policy')
                  }}</a></span
                ></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </div>
</template>
<script setup lang="ts">
import footerLogo from '@/assets/images/arly/arly_logo_cream.svg'
import footerSwoop from '@/assets/images/arly/footerSwoop.svg'

import { useI18n, vuetify } from 'arly-common'

import { PRIVACY_POLICY_URL } from '@/constants/urls'

const { t } = useI18n()
const { smAndDown, xs } = vuetify.vuetifyLib.useDisplay()

const currentYear = new Date().getFullYear()
</script>

<style lang="scss" scoped>
#footer {
  background: rgba(var(--v---Primary-700));
  min-width: 100dvw;
  position: relative;
  bottom: 0;
  height: calc(auto + 6.5rem);
}
.footer-main-container {
  position: relative;
}
.footer-swoop {
  min-width: 100dvw;
  position: absolute;
  top: -40px;
  width: 100%;

  &__mobile {
    min-width: 100dvw;
    position: absolute;
    top: -40px;
    width: 100%;
    top: -15px;

    &-xs {
      top: -10px;
    }
  }
}

.footer {
  &__item {
    color: rgba(var(--v---Background-100));
    font-size: var(--v---Label-200);
    line-height: 1.7rem;
    font-weight: var(--v---Weight-500);
  }

  &__col-start {
    text-align: start;
    padding-left: 0;
  }

  &__col-end {
    text-align: end;
    padding-right: 0;
  }

  &__divider {
    width: 100%;
  }

  &-link {
    color: inherit;
    text-decoration: none;
  }
}
</style>
