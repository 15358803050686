{
  "all_rights_reserved": "Todos los derechos reservados.",
  "create_your_account": "Crear cuenta",
  "email": "Email",
  "get_started": "Empezar",
  "greeting": "¡Hola!",
  "i18n_cancel": "Cancelar",
  "i18n_change_password": "Cambiar contraseña",
  "i18n_close": "Cerrar",
  "i18n_current_password": "Contraseña actual",
  "i18n_edit_profile": "Editar Perfil",
  "i18n_email_address": "Dirección de email",
  "i18n_field_is_required": "Este campo es requerido",
  "i18n_first_name": "Primer Nombre",
  "i18n_invalid_email": "Email inválido",
  "i18n_last_name": "Apellido",
  "i18n_account": "Cuenta",
  "i18n_logout": "Logout",
  "i18n_new_password": "Nueva contraseña",
  "i18n_new_password_not_same_as_current_password": "La nueva contraseña no puede ser igual a la actual",
  "i18n_only_alphabetic_characters": "Disculpa, por favor sólo usa caracteres alfabéticos. Guiones y apóstrofes también son permitidos",
  "i18n_oops_error": "¡Ups! Algo salió mal",
  "i18n_password": "Contraseña",
  "i18n_password_successfully_updated": "Contraseña actualizada exitosamente",
  "i18n_password_updated": "Contraseña actualizada",
  "i18n_phone_number": "Número de teléfono",
  "i18n_please_enter_new_password": "Por favor ingresa una nueva contraseña",
  "i18n_profile": "Perfil",
  "i18n_repeat_password": "Repetir contraseña",
  "i18n_save_settings": "Guardar configuración",
  "i18n_update_password": "Actualizar contraseña",
  "i18n_update_profile_picture": "Actualizar imagen de perfil",
  "i_agree_to_the": "Acepto",
  "medium": "Media",
  "message": "Bienvenido a mi aplicación.",
  "passwords": "Contraseñas",
  "i18n_passwords_do_not_match": "La contraseña reingresada no coincide con la nueva contraseña.",
  "privacy_policy": "Política de privacidad",
  "register_tooltip": "Podrás cambiar tu correo una vez registrado",
  "strong": "Fuerte",
  "terms_of_service": "Términos de servicio",
  "weak": "Débil",
  "welcome_to_arly": "Bienvenido a Arly",
  "i18n_save_button": "Guardar",
  "i18n_cancel_button": "Cancelar",
  "i18n_upload_button": "Cargar",
  "i18n_use_camera_button": "usar camara",
  "i18n_profile_photo_title": "Agregar Foto",
  "i18n_profile_photo_description": "Tome o cargue su foto de perfil. La foto debe estar en formato JPG o PNG y no debe superar 1 MB de tamaño",
  "i18n_poppover_title": "¿Estás seguro de que quieres borrar tu foto?",
  "i18n_pooppover_action": "Eliminar foto de perfil",
  "i18n_close_dialog": "Cerrar Dialogo",
  "i18n_upload_profile_picture": "Subir foto de perfil",
  "i18n_profile_picture": "Imagen de perfil",
  "i18n_not_found": "No encontrado",
  "i18n_profile_picture_uploaded": "Imagen de perfil cambiada con éxito",
  "i18n_profile_picture_deleted": "Imagen de perfil borrada con éxito",
  "i18n_profile_picture_error": "Error cargando la imagen",
  "i18n_profile_picture_error_saving": "Error guardando la imagen de perfil",
  "i18n_profile_picture_not_supported_error": "Tuvimos problemas para subir tu foto. Asegúrese de que sea JPG o PNG y que no supere 1 MB de tamaño.",
  "i18n_profile_picture_camera_error": "Error guardando la imagen de camera",
  "i18n_user_profile_saved": "Perfil de usuario guardado",
  "i18n_user_profile_saving_error": "Error guardando el perfil de usuario",
  "brought_to_you_by": "Traído a usted por",
  "power_your_purpose": "Potencia tu propósito",
  "bell_fundation": "B.E.L.L. Fundación",
  "i18n_registration_validation_token_error": "Error validando el token de registro",
  "i18n_registration_fails": "Error registrando el usuario"
}
