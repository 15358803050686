import '@/assets/fonts/montserrat-font.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/main.scss'
// Vuetify
import 'vuetify/styles'

import { Auth0Plugin, createAuth0 } from '@auth0/auth0-vue'
import { LDPlugin } from 'launchdarkly-vue-client-sdk'

import App from '@/App.vue'
import { configureHttpClients } from '@/utils/http-client'
import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'

import { createPinia } from 'pinia'

// Load translations
import en from '@/locales/en.json'
import es from '@/locales/es.json'

import router from '@/router'
import { i18n, vuetify } from 'arly-common'
import 'arly-common/dist/style.css'

// Auth0
const auth0: Auth0Plugin = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE
  }
})

// Vuetify
const gtm = createGtm({
  id: import.meta.env.VITE_GTM_CODE,
  defer: false,
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false
})

configureHttpClients(auth0)

const app = createApp(App)
app.use(createPinia())
app.use(LDPlugin, { clientSideID: import.meta.env.VITE_LD_CLIENT_ID })
app.use(router)
app.use(vuetify.vuetifyConfig())
app.use(gtm)
app.use(auth0)
app.use(i18n({ en, es }))
app.mount('#app')
