import type { Auth0VueClient } from '@auth0/auth0-vue'
import type IGenericApiResponse from '@/models/GenericApiResponse'
import type IRegisterToken from '@/models/RegisterToken'
import { type IUser } from 'arly-common'
import type IUserUpdatePassword from '@/models/UserUpdatePassword'
import type { IAccounts } from '@/models/Accounts'
import { adminClient, identityClient, cmsClient } from '@/utils/http-client'
import type IUserPicture from '@/models/UserPicture'

function getMSUrl(optionId: number): Promise<string> {
  return cmsClient.get(`/lms/token/${optionId}`)
}

async function getUserInfo(): Promise<IUser> {
  return identityClient.get('/users/info')
}

async function getAccountsInfo(accountId: number): Promise<IAccounts> {
  return adminClient.get(`/accounts/${accountId}`)
}

async function validateEmailToken(token: string): Promise<IRegisterToken> {
  return identityClient.post('/users/token/validation', { token: token })
}

async function logoutUser(auth0: Auth0VueClient): Promise<void> {
  return auth0.logout({
    logoutParams: {
      returnTo: window.location.origin
    }
  })
}

async function registerUserPassword(token: string, password: string): Promise<IGenericApiResponse> {
  return identityClient.put('/users/password', {
    IsTemporaryPassword: false,
    confirmPassword: password,
    password: password,
    passwordResetToken: token
  })
}

async function updateUserData(userData: IUser): Promise<void> {
  return identityClient.put(`/users/${userData.id}`, { ...userData })
}

async function updateUserPicture(userData_id: number, imgData: IUserPicture): Promise<void> {
  return identityClient.put(`/users/profile/${userData_id}`, { ...imgData })
}
async function updatePassword(userData: IUserUpdatePassword): Promise<IGenericApiResponse> {
  return identityClient.put(`/users/password/change`, { ...userData })
}

export {
  getUserInfo,
  getAccountsInfo,
  validateEmailToken,
  logoutUser,
  registerUserPassword,
  updateUserData,
  updateUserPicture,
  updatePassword,
  getMSUrl
}
