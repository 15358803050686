<template>
  <v-container v-if="user" :fluid="true" class="pa-0">
    <v-row class="user-profile-container mb-15">
      <v-row v-if="accountName" class="d-sm-none">
        <v-col cols="12" sm="12" class="mb-3 text-center">
          <strong>{{ t('i18n_account') }}</strong>
          <p class="user-profile-form__account-name--mobile">{{ accountName }}</p>
        </v-col>
      </v-row>

      <UserProfilePictureLoader :edit-mode="false" />

      <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="user-profile-form ml-12">
        <v-row v-if="accountName" class="d-none d-md-block">
          <v-col
            cols="12"
            sm="6"
            md="12"
            lg="6"
            xl="6"
            xxl="6"
            class="mb-3 user-profile-form--border-bottom pl-0"
          >
            <span class="user-profile-form__account-title">{{ t('i18n_account') }}: </span>
            <span class="user-profile-form__account-name">
              {{ accountName }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="mb-3 pl-0">
            <span class="user-profile-form__username">{{
              `${user.firstName} ${user.lastName}`
            }}</span>
          </v-col>

          <v-col class="d-none d-sm-block" offset-md="3">
            <a class="d-block profile-link" href="javascript://" @click="handleEditMode">
              {{ t('i18n_edit_profile') }}
            </a>

            <a class="d-block profile-link" href="javascript://" @click="handleLogout">
              {{ t('i18n_logout') }}
            </a>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mb-6 pl-0">
            <v-row>
              <v-col class="d-sm-none">
                <a class="d-block profile-link" href="javascript://" @click="handleEditMode">
                  {{ t('i18n_edit_profile') }}
                </a>
              </v-col>

              <v-col cols="12" md="6" lg="6" xl="4">
                <h4 class="user-profile-form__label">{{ t('i18n_phone_number') }}</h4>
                <p>{{ formatPhoneNumber(user.phoneNumber) }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="10" lg="7" xl="5">
            <v-row>
              <v-col cols="12" md="6 pl-0">
                <h4 class="user-profile-form__label">{{ t('i18n_email_address') }}</h4>
                <p class="user-profile-form__email">{{ user.email }}</p>
              </v-col>

              <v-col cols="12" md="6 pl-0">
                <h4 class="user-profile-form__label">{{ t('i18n_password') }}</h4>
                <a class="profile-link" href="javascript://" @click="showPasswordDialog">
                  {{ t('i18n_change_password') }}
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="user-profile-confirm-container">
      <v-col
        cols="12"
        sm="4"
        offset-sm="1"
        md="4"
        offset-md="1"
        lg="3"
        offsset-lg="3"
        xl="3"
        offset-xl="3"
        xxl="3"
      >
        <v-btn rounded="xl" class="user-profile-confirm-container__btn-confirm" :disabled="true">
          {{ t('i18n_save_settings') }}
        </v-btn>
      </v-col>

      <v-col class="d-sm-none">
        <a class="d-block profile-link" href="javascript://" @click="handleLogout">
          {{ t('i18n_logout') }}
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useI18n, formatPhoneNumber, UserRoleEnum, type IUser, type IUserRole } from 'arly-common'
import { useAuth0, type Auth0VueClient } from '@auth0/auth0-vue'
import { getAccountsInfo, logoutUser } from '@/services/UserService'
import UserProfilePictureLoader from './UserProfilePictureLoader.vue'
import { useUserStore } from '@/stores/UserStore'
import { onBeforeMount, ref } from 'vue'

const auth0: Auth0VueClient = useAuth0()
const { t } = useI18n()

const userStore = useUserStore()
const user = ref<IUser | undefined>(userStore.userDataVal)
const accountName = ref('')

const emit = defineEmits<{
  (e: 'setEditMode', active: boolean): void
  (e: 'showPasswordReset'): void
}>()

onBeforeMount(async () => {
  if (typeof user.value !== 'undefined') {
    const highestRole = Math.min(...user.value.userRoles.map((role: IUserRole) => role.roleId))
    const highestRoleData = user.value.userRoles.find((el: IUserRole) => el.roleId === highestRole)

    if (typeof highestRoleData !== 'undefined' && highestRole > UserRoleEnum.bxContentAdmin) {
      const accountInfo = await getAccountsInfo(highestRoleData.accountId)
      accountName.value = accountInfo.accountName
    }
  }
})

function handleEditMode(): void {
  emit('setEditMode', true)
}

function handleLogout(): void {
  logoutUser(auth0)
}

function showPasswordDialog(): void {
  emit('showPasswordReset')
}
</script>

<style lang="scss" scoped src="@/components/user-profile/UserProfileComponent.scss"></style>
