import type { AxiosError, AxiosInstance } from 'axios'

import type { Auth0Plugin } from '@auth0/auth0-vue'
import type { Auth0VueClient } from '@auth0/auth0-vue'

import axios from 'axios'
import router from '@/router'

export const adminClient = axios.create()
export const cmsClient = axios.create()
export const enrollmentClient = axios.create()
export const familyClient = axios.create()
export const identityClient = axios.create()

/**
 * Define a mapping object to handle error status codes and corresponding handlers
 * */
const errorHandlers: { [key: number]: (error: AxiosError, auth0: Auth0VueClient) => void } = {
  401: (_, auth0: Auth0VueClient) => {
    auth0
      .logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      })
      .then(() => router.push('/'))
  }, // Redirect to the login page if the user is not authenticated
  404: () => router.push('/not-found'), // Handle other status errors
  500: (error) => Promise.reject(error.response?.data)
  // Add other status codes and corresponding handlers here
}

function basicSetup(service: string, httpClient: AxiosInstance, apiURL: string): void {
  if (import.meta.env.VITE_NODE_ENV === 'dev') {
    httpClient.defaults.baseURL = `${import.meta.env.VITE_API_BASE_URL}${service}`
  } else {
    httpClient.defaults.baseURL = apiURL
  }

  httpClient.defaults.headers['Content-Type'] = 'application/json'
}

function setRequestInterceptors(httpClient: AxiosInstance, auth0: Auth0VueClient): void {
  // request interceptor
  httpClient.interceptors.request.use(
    async (config) => {
      if (auth0.isAuthenticated.value) {
        try {
          // Get the access token from the Auth0 client
          const token: string = await auth0.getAccessTokenSilently()
          if (token) {
            // Get the access token generated by the Arly API

            config.headers.Authorization = `Bearer ${token}`
          }
        } catch (err) {
          console.error(`Error getting access token: ${err}`)
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

function setAxiosInterceptors(httpClient: AxiosInstance, auth0: Auth0VueClient): void {
  httpClient.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error: AxiosError) => {
      const errorHandler = errorHandlers[error?.response?.status || 500]

      if (errorHandler) {
        // Call the corresponding error handler if it exists
        errorHandler(error, auth0)
      } else {
        // Show a generic error message for other status codes
        throw new Error('An error occurred. Please try again later.')
      }
      return Promise.reject(error)
    }
  )
}

export const configureAdminClient = (auth0: Auth0VueClient): void => {
  basicSetup('admin', adminClient, import.meta.env.VITE_API_BASE_ADMIN_URL)
  setRequestInterceptors(adminClient, auth0)
  setAxiosInterceptors(adminClient, auth0)
}

export const configureCmsClient = (auth0: Auth0VueClient): void => {
  basicSetup('cms', cmsClient, import.meta.env.VITE_API_BASE_CMS_URL)
  setRequestInterceptors(cmsClient, auth0)
  setAxiosInterceptors(cmsClient, auth0)
}

export const configureEnrollmentClient = (auth0: Auth0VueClient): void => {
  basicSetup('enrollment', enrollmentClient, import.meta.env.VITE_API_BASE_ENROLLMENT_URL)
  setRequestInterceptors(enrollmentClient, auth0)
  setAxiosInterceptors(enrollmentClient, auth0)
}

export const configureFamilyClient = (auth0: Auth0VueClient): void => {
  basicSetup('family', familyClient, import.meta.env.VITE_API_BASE_FAMILY_URL)
  setRequestInterceptors(familyClient, auth0)
  setAxiosInterceptors(familyClient, auth0)
}

export const configureIdentityClient = (auth0: Auth0VueClient): void => {
  basicSetup('identity', identityClient, import.meta.env.VITE_API_BASE_IDENTITY_URL)
  setRequestInterceptors(identityClient, auth0)
  setAxiosInterceptors(identityClient, auth0)
}

export const configureHttpClients = (auth0: Auth0Plugin): void => {
  configureAdminClient(auth0)
  configureCmsClient(auth0)
  configureEnrollmentClient(auth0)
  configureFamilyClient(auth0)
  configureIdentityClient(auth0)
}
