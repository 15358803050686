<template>
  <v-dialog
    v-model="isDialogOpen"
    :fullscreen="mdAndDown"
    :class="{ dialog: !smAndDown }"
    persistent
    no-click-animation
    @update:model-value="onCloseDialog"
  >
    <v-card :class="{ dialog: smAndDown }">
      <div class="dialog__wave"></div>
      <div class="dialog__header">
        <v-container>
          <v-row align="center" no-gutters class="dialog__header--spacing">
            <v-col :cols="smAndDown ? 12 : 4" :offset="smAndDown ? 0 : 4" align="center">
              <h2 class="dialog__header--text">
                {{ t('i18n_profile_photo_title') }}
              </h2>
            </v-col>

            <v-col v-if="!smAndDown" cols="4" align="end">
              <img
                role="button"
                src="@/assets/images/icons/closeIcon.svg"
                :alt="t('i18n_close_dialog')"
                @click="onCloseDialog"
              />
            </v-col>
            <v-btn
              v-if="smAndDown"
              density="compact"
              size="large"
              icon="fa fa-xmark"
              elevation="0"
              class="dialog__header--close"
              @click="onCloseDialog"
              ><img
                role="button"
                src="@/assets/images/icons/closeIcon.svg"
                :alt="t('i18n_close_dialog')"
                @click="onCloseDialog"
            /></v-btn>
          </v-row>
        </v-container>
      </div>

      <div class="dialog__content">
        <v-container>
          <v-row align="center">
            <v-col cols="12" align="center">
              <div v-if="cameraMode" class="img__container">
                <video autoplay loop playsinline class="feed"></video>
                <canvas style="display: none"></canvas>
              </div>

              <section
                v-if="cropperModeOn"
                style="position: relative"
                class="dialog__content--cropper-area"
              >
                <cropper
                  ref="cropperRef"
                  style="height: 700px"
                  class="cropper"
                  :default-boundaries="boundaries"
                  :src="avatarSrc"
                  :stencil-props="{
                    aspectRatio: 1
                  }"
                  :resize-image="{
                    adjustStencil: false
                  }"
                  :zoom="zoomFactor"
                  :size="size"
                  :auto-crop="true"
                  :auto-zoom="true"
                  :auto-center="true"
                  :auto-crop-color="true"
                  :transitions="true"
                  @change="changeCropperSrc"
                  @ready="cropperReady = true"
                >
                </cropper>

                <div v-show="cropperReady" style="position: absolute; width: 100%; bottom: -5px">
                  <p style="position: absolute; top: 40px; left: 50px; color: white">zoom</p>
                  <v-slider
                    v-model="zoomFactor"
                    density="comfortable"
                    class="mt-5"
                    color="grey"
                    track-color="#e9e9e9"
                    thumb-color="#566781"
                    append-icon="clg-add"
                    prepend-icon="clg-minus"
                    thumb-size="16"
                    track-size="8"
                    @end="setZoom"
                    @start="oldZoom"
                  />
                </div>
              </section>

              <div
                v-if="!cameraMode && !cropperModeOn"
                :class="avatarSrcAdded ? 'img__container' : ''"
              >
                <img
                  id="updatedImg"
                  :class="
                    avatarSrcAdded
                      ? 'dialog__content--avatar--full-width img__container'
                      : 'dialog__content--avatar'
                  "
                  :src="avatarSrc"
                  :alt="t('i18n_profile_picture')"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-card-text v-show="isTextVisible" class="dialog__content--text">
          {{ t('i18n_profile_photo_description') }}
        </v-card-text>

        <v-container class="dialog__content--actions">
          <v-row v-if="cameraMode" align="center">
            <v-col cols="12" align="center">
              <v-btn
                variant="plain"
                class="mt-4 elevation-0 dialog__content--actions--button-secondary"
                rounded="xl"
                size="large"
                bx-attr="get-started"
                type="submit"
                @click="takeSnapShot()"
                >{{ t('i18n_save_button') }}</v-btn
              >
            </v-col>
          </v-row>

          <v-row
            v-if="!cameraMode"
            :class="{ 'dialog__actions-container': smAndDown }"
            align="center"
          >
            <v-col
              v-if="imgPreloaded && !smAndDown && avatarSrc !== PlaceholderImage"
              class="mt-3"
              cols="2"
              align="center"
              justify="center"
            >
              <div class="text-center">
                <v-menu v-model="showConfirmation" :close-on-content-click="false" location="end">
                  <template v-if="avatarSrcAdded" #activator="{ props: templateProps }">
                    <v-btn
                      density="compact"
                      icon="fa fa-trash"
                      elevation="0"
                      v-bind="templateProps"
                    >
                    </v-btn>
                  </template>

                  <v-card class="pa-6 dialog__popover" :rounded="20" width="270" min-width="270">
                    <v-row align="center" no-gutters class="dialog__header--spacing pa-0">
                      <v-col cols="12" align="center">
                        <p>{{ t('i18n_poppover_title') }}</p>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-row align="center" no-gutters class="dialog__header--spacing pa-0">
                        <v-col cols="12" align="center">
                          <v-btn
                            variant="plain"
                            class="mt-4 elevation-0 dialog__content--actions--button-secondary"
                            rounded="xl"
                            size="large"
                            bx-attr="get-started"
                            type="button"
                            @click="showConfirmation = false"
                            >{{ t('i18n_cancel_button') }}</v-btn
                          >
                        </v-col>
                        <v-col cols="12" align="center" class="mt-3">
                          <a
                            role="button"
                            class="dialog__content--actions--delete-profile"
                            @click="deletePhoto"
                            >{{ t('i18n_pooppover_action') }}
                          </a>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </v-col>
            <v-col :cols="smAndDown ? 6 : actionsCols" align="center">
              <v-btn
                variant="plain"
                class="mt-4 elevation-0 dialog__content--actions--button-primary"
                rounded="xl"
                size="large"
                bx-attr="get-started"
                type="submit"
                :disabled="isLoading"
                @click="handleUseCamera()"
                >{{ t('i18n_use_camera_button') }}</v-btn
              >
            </v-col>

            <v-col :cols="smAndDown ? 6 : actionsCols" align="center">
              <input
                ref="file"
                :aria-label="t('i18n_upload_profile_picture')"
                type="file"
                hidden
                @:change="handleFileUpload()"
              />

              <v-btn
                variant="plain"
                class="mt-4 elevation-0 dialog__content--actions--button-primary"
                rounded="xl"
                size="large"
                bx-attr="get-started"
                type="submit"
                :disabled="isLoading"
                @click="onToggleFile"
                >{{ t('i18n_upload_button') }}</v-btn
              >
            </v-col>

            <v-col
              v-if="avatarSrc !== PlaceholderImage || imgPreloaded"
              :cols="smAndDown ? 6 : actionsCols"
              align="center"
            >
              <v-btn
                variant="plain"
                class="mt-4 elevation-0 dialog__content--actions--button-secondary"
                rounded="xl"
                size="large"
                bx-attr="get-started"
                :disabled="isLoading"
                :loading="isLoading"
                @click="onSave"
                >{{ t('i18n_save_button') }}</v-btn
              >
            </v-col>

            <v-col
              v-if="imgPreloaded && smAndDown && avatarSrc !== PlaceholderImage"
              class="mt-3"
              cols="2"
              align="center"
              justify="center"
            >
              <div class="text-center">
                <v-menu v-model="showConfirmation" :close-on-content-click="false" location="end">
                  <template v-if="avatarSrcAdded" #activator="{ props: templateProps }">
                    <v-btn
                      density="compact"
                      icon="fa fa-trash"
                      elevation="0"
                      v-bind="templateProps"
                      :disabled="isLoading"
                    >
                    </v-btn>
                  </template>

                  <v-card class="pa-6 dialog__popover" :rounded="20" width="270" min-width="270">
                    <v-row align="center" no-gutters class="dialog__header--spacing pa-0">
                      <v-col cols="12" align="center">
                        <p>{{ t('i18n_poppover_title') }}</p>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-row align="center" no-gutters class="dialog__header--spacing pa-0">
                        <v-col cols="12" align="center">
                          <v-btn
                            variant="plain"
                            class="mt-4 elevation-0 dialog__content--actions--button-secondary"
                            rounded="xl"
                            size="large"
                            bx-attr="get-started"
                            type="button"
                            @click="showConfirmation = false"
                            >{{ t('i18n_cancel_button') }}</v-btn
                          >
                        </v-col>
                        <v-col cols="12" align="center" class="mt-3">
                          <a role="button" class="delete-profile" @click="deletePhoto"
                            >{{ t('i18n_pooppover_action') }}
                          </a>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch, inject } from 'vue'
import { updateUserPicture } from '@/services/UserService'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import PlaceholderImage from '@/assets/images/icons/user-avatar.svg'
import type IUserPicture from '@/models/UserPicture'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useI18n, type ICustomSnackbarProps, IconType, MessageType, vuetify } from 'arly-common'
import type { IUserProfileToast } from '@/models/UserProfileToast'

const { t } = useI18n()
const userStore = useUserStore()
const { userDataVal } = storeToRefs(userStore)
const props = defineProps<{
  dialog: boolean
}>()
const emit = defineEmits<{
  (e: 'toggle-modal', visible: boolean): void
}>()
const file = ref<HTMLInputElement | null>(null)
const isLoading = ref<boolean>(false)
const avatarSrc = ref<string>(userDataVal.value?.profilePicture[0].imageUrl || PlaceholderImage)
const avatarSrcAdded = ref(!!userDataVal.value?.profilePicture[0].imageUrl)
const cameraMode = ref(false)
const cropperModeOn = ref(false)
const cropperSrc = ref<string>('')
const cropperRef = ref()
const zoomFactor = ref(0)
const oldZoomFactor = ref(0)
const cropperReady = ref(false)
const showConfirmation = ref(false)
const size = ref({
  width: 0,
  height: 0
})
const isDialogOpen = ref(false)
const { mdAndDown, smAndDown } = vuetify.vuetifyLib.useDisplay()
const pictureWidth = 1280
const handleToastMessage =
  inject<({ toastProps, toastColorValue }: IUserProfileToast) => void>('handleToastMessage')

watch(props, () => {
  const { dialog } = props
  isDialogOpen.value = dialog
})

watch(userDataVal, () => {
  avatarSrc.value = userDataVal.value?.profilePicture[0].imageUrl || PlaceholderImage
  avatarSrcAdded.value = !!userDataVal.value?.profilePicture[0].imageUrl
})
function resetZoom(): void {
  zoomFactor.value = 0
  oldZoomFactor.value = 0
}

function displayToastMessage({
  type,
  message
}: {
  type: 'success' | 'error'
  message: string
}): void {
  let toastProps: ICustomSnackbarProps = {
    icon: IconType.error,
    message: t(message),
    title: t('i18n_profile_picture')
  }

  let toastColorValue = MessageType.error

  if (type === 'success') {
    toastProps = {
      icon: IconType.success,
      message: t(message),
      title: t('i18n_profile_picture')
    }
    toastColorValue = MessageType.success
  }

  handleToastMessage?.({ toastProps, toastColorValue })
}

async function handleFileUpload(): Promise<void> {
  cropperModeOn.value = true

  cameraMode.value = false
  resetZoom()

  const fileSizeDimensions = 1024 * 1024
  const selectedFile = file.value && file.value.files?.[0]
  const maxfilesize: number = fileSizeDimensions
  const filesize: number = selectedFile ? selectedFile.size : 0
  if (
    filesize < maxfilesize &&
    (selectedFile?.type === 'image/jpeg' || selectedFile?.type === 'image/png')
  ) {
    avatarSrcAdded.value = true
    avatarSrc.value = ''
    getBase64(selectedFile)
  } else {
    avatarSrc.value = userDataVal.value?.profilePicture[0].imageUrl || PlaceholderImage
    avatarSrcAdded.value = !!userDataVal.value?.profilePicture[0].imageUrl

    cropperModeOn.value = false
    displayToastMessage({ type: 'error', message: 'i18n_profile_picture_not_supported_error' })
  }
}

async function getBase64(file: File): Promise<FileReader> {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = async (): Promise<void> => {
    const result = (await reader.result) as string
    avatarSrc.value = result
  }
  reader.onerror = (): void => {
    avatarSrc.value = PlaceholderImage
  }

  return reader
}

function handleUseCamera(): void {
  cameraMode.value = true
  cropperModeOn.value = false
  resetZoom()

  if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
    let constraints = {
      video: {
        width: {
          min: 640,
          ideal: pictureWidth,
          max: 1920
        },
        height: {
          min: 360,
          ideal: 720,
          max: 1080
        }
      }
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const videoPlayer: HTMLMediaElement | null = document.querySelector('video')
        if (videoPlayer) {
          videoPlayer.srcObject = stream
          videoPlayer.play()
        }
      })
      .catch(() => {
        displayToastMessage({ type: 'error', message: 'i18n_profile_picture_camera_error' })
      })
  }
}

function onToggleFile(): void {
  if (file.value) {
    file.value.click()
  }
}

function onCloseDialog(): void {
  emit('toggle-modal', false)

  cameraMode.value = false
  cropperModeOn.value = false
  file.value = null
  resetZoom()
  closeCamera()
}

function closeCamera(): void {
  const videoPlayer: HTMLMediaElement | null = document.querySelector('video')
  if (videoPlayer) {
    const tracks = (videoPlayer?.srcObject as MediaStream).getTracks()
    tracks.forEach((track) => track.stop())
  }
}

function takeSnapShot(): void {
  const screenRatio = window.innerHeight < window.innerWidth ? 16 / 9 : 9 / 16

  const picture: HTMLCanvasElement | null = document.querySelector('canvas')
  if (picture) {
    picture.width = pictureWidth
    picture.height = window.innerWidth / screenRatio
  }

  const ctx = picture?.getContext('2d')
  if (ctx) {
    ctx.imageSmoothingEnabled = true
    ctx.imageSmoothingQuality = 'high'
    ctx.drawImage(
      document.querySelector('video') as CanvasImageSource,
      0,
      0,
      picture?.width || 0,
      picture?.height || 0
    )
  }

  avatarSrc.value = picture?.toDataURL('image/png') || ''
  cropperModeOn.value = true
  cameraMode.value = false
  closeCamera()
}

function changeCropperSrc(
  cropObj: CanvasRenderingContext2D & { coordinates: { width: number; height: number } }
): void {
  cropperSrc.value = cropObj.canvas.toDataURL()

  size.value.width = Math.round(cropObj.coordinates.width)
  size.value.height = Math.round(cropObj.coordinates.height)
}

function cropImg(): void {
  cropperModeOn.value = false
  avatarSrcAdded.value = true
}

async function setUserPicture(image: IUserPicture): Promise<void> {
  try {
    isLoading.value = true
    const userData = userStore.userDataVal || null
    if (userData) {
      await updateUserPicture(userData.id, image).then(() => {
        userStore.setUserPicture(image)
        const message = image.contentBytes
          ? 'i18n_profile_picture_uploaded'
          : 'i18n_profile_picture_deleted'
        displayToastMessage({ type: 'success', message })
        onCloseDialog()
        return userStore.fetchUserData()
      })
    }
  } catch {
    displayToastMessage({ type: 'error', message: 'i18n_profile_picture_error' })
  } finally {
    isLoading.value = false
  }
}

function onSave(): void {
  const image = {
    contentBytes: '',
    contentType: ''
  }

  if (avatarSrc.value !== PlaceholderImage) {
    cropImg()
    image.contentBytes = cropperSrc.value.split(',')[1]
    image.contentType = 'image/png'
  }
  setUserPicture(image)
}

const isTextVisible = computed(() => {
  return !cameraMode.value && avatarSrc.value === PlaceholderImage
})

const imgPreloaded = computed(() => {
  return !!userDataVal.value?.profilePicture[0].imageUrl
})

const actionsCols = computed(() => {
  if (avatarSrc.value !== PlaceholderImage && !imgPreloaded.value) {
    return 4
  } else if (imgPreloaded.value) {
    return avatarSrc.value === PlaceholderImage ? 4 : 3
  } else {
    return 6
  }
})

function oldZoom(oldFactor: any): void {
  oldZoomFactor.value = oldFactor
}

function setZoom(): void {
  const diff = (oldZoomFactor.value - zoomFactor.value) / 100
  const factor = 1
  const range = Math.abs(diff)

  if (range > 0) {
    for (let i = 0; i < range; i++) {
      cropperRef.value.zoom(factor - diff)
    }
  }
}

function boundaries({ cropper }: { cropper: { clientWidth: number; clientHeight: number } }): {
  width: number
  height: number
} {
  return {
    width: cropper.clientWidth,
    height: cropper.clientHeight
  }
}

function deletePhoto(): Promise<void> {
  avatarSrc.value = PlaceholderImage
  avatarSrcAdded.value = false
  showConfirmation.value = false
  cameraMode.value = false
  cropperModeOn.value = false

  const image = {
    contentBytes: '',
    contentType: ''
  }
  userStore.setUserPicture({ contentBytes: '', contentType: '' })
  return setUserPicture(image)
}
</script>
<style lang="scss" scoped>
.dialog {
  max-height: 37.375rem;
  max-height: 43.75rem;
  height: 80%;
  max-width: 44rem;

  @media only screen and (max-width: $sm-limit-breakpoint) {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 90vh;
    margin-top: 10vh;
  }

  &__wave {
    background: $background-blue;
    content: '';
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-bottom-right-radius: 45%;
    border-bottom-left-radius: 45%;
    position: absolute;
    width: 120%;
    top: -1.313rem;
    height: 6.25rem;
  }

  &__header {
    position: absolute;
    top: 0;
    width: 100%;

    &--spacing {
      padding: 0.938rem 1.875rem;

      @media only screen and (max-width: $sm-limit-breakpoint) {
        padding: 0px 1.875rem;
      }
    }

    &--close {
      position: fixed;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 3.625rem;
      height: 3rem;
      width: 3rem;
      background: $primary-blue;
    }

    &--text {
      color: white;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: calc(100% - 5.063rem);
    overflow: auto;
    margin-top: 9.375rem;

    @media only screen and (max-width: $sm-limit-breakpoint) {
      height: auto;
    }

    &--avatar {
      width: 13.125rem;
      height: 12.5rem;

      &--full-width {
        width: 100%;
        height: 12.5rem;
      }
    }

    &--text {
      font-family: $Montserrat;
      font-size: 1rem;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $primary-gray;
      text-align: center;
      padding: 0 1.875rem;
    }

    &--actions {
      padding-top: 0;
      margin-bottom: 3.75rem;
      margin-top: 2.5rem;

      &--button-primary {
        background: $white-color;
        border: 0.063rem solid $smallbtn-border;
        cursor: pointer;
        color: $smallbtn-border;
        width: 100%;

        &:hover {
          box-shadow: -3px 2px 3px 0 rgba(0, 0, 0, 0.12);
          color: $primary-orange;
          border-color: $primary-orange;
        }
      }

      &--button-secondary {
        background: $primary-orange;
        display: block;
        color: $primary-button;
        width: 100%;

        cursor: pointer;
        color: $smallbtn-border;

        &:hover {
          box-shadow: -3px 2px 3px 0 rgba(0, 0, 0, 0.12);
          color: $smallbtn-border;
        }
      }

      &--delete-profile {
        color: $smallbtn-border;
        text-decoration: underline;
        text-decoration-color: $smallbtn-border;
        cursor: pointer;
      }
    }

    &--cropper-area {
      height: 17.5rem;
    }
  }

  .img__container {
    width: 100%;
    max-height: 17.5rem;
    height: 21rem;
    background: rgba(0, 0, 0, 0.5);

    video {
      height: 100%;
      width: 100%;
    }

    img {
      width: 80%;
      height: 100%;
    }
  }

  &__popover {
    position: relative;
    bottom: 3.125rem;
    right: 12.5rem;

    @media only screen and (max-width: $sm-limit-breakpoint) {
      right: 9.375rem;
    }
  }

  &__actions-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
