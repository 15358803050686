import { defineStore } from 'pinia'
import type { IUser, IUserRole } from 'arly-common'
import { computed, ref } from 'vue'
import type IUserPicture from '@/models/UserPicture'
import type IUserProfileError from '@/models/UserUpdateError'
import { getUserInfo } from '@/services/UserService'

export const useUserStore = defineStore('user', () => {
  const userData = ref<IUser>() //this should be removed as soon as okta provides the correct user data
  const userPicture = ref<IUserPicture>()
  const isLoading = ref(false)

  function setUserData(data: IUser): void {
    userData.value = data
  }

  function setUserPicture(picture: IUserPicture): void {
    userPicture.value = picture
  }

  async function fetchUserData(): Promise<void | IUserProfileError> {
    try {
      isLoading.value = true
      userData.value = await getUserInfo()
      isLoading.value = false
    } catch (error) {
      return error as IUserProfileError
    }
  }

  const userDataVal = computed(() => userData.value)
  const userPictureVal = computed(() => userPicture.value)
  const finalRole = computed(() => {
    if (userData.value) {
      const { userRoles } = userData.value

      const highestRole = Math.min(...userRoles.map((role: IUserRole) => role.roleId))
      const highestRoleData = userRoles.find((el: IUserRole) => el.roleId === highestRole)

      return highestRole <= 3 ? userRoles[userRoles.length - 1] : highestRoleData ?? undefined
    }
    return null
  })

  const isLoadingUser = computed(() => isLoading.value)

  return {
    setUserData,
    setUserPicture,
    userDataVal,
    userPictureVal,
    fetchUserData,
    finalRole,
    isLoadingUser
  }
})
