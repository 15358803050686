<template>
  <div class="register-wrapper">
    <div v-if="!isLoading">
      <div class="d-flex justify-start mb-12 pb-8">
        <v-img src="src/assets/images/arly/Arly_Logo_Green.png" class="arly-logo" cover />
      </div>
      <div class="d-flex flex-column align-center">
        <div class="form-container">
          <v-img :src="headerLogo" class="top-panda" />
          <v-form ref="refVForm" class="form register-form-group" @submit.prevent="onSubmit">
            <div v-if="userName" class="hi-label">{{ t('greeting') }} {{ userName }}!</div>
            <h1 class="welcome-label">{{ t('welcome_to_arly') }}</h1>
            <span class="complete-account">{{ t('create_your_account') }}</span>
            <div class="form-group mt-10">
              <span class="form-label">
                <v-tooltip :text="t('register_tooltip')">
                  <template #activator="{ props }">
                    {{ t('email') }}
                    <v-icon
                      density="compact"
                      size="x-small"
                      color="gray"
                      icon="fas fa-circle-info"
                      v-bind="props"
                    ></v-icon>
                  </template>
                </v-tooltip>
              </span>
              <v-text-field
                v-model="email"
                disabled
                bx-attr="register-email"
                append-inner-icon="fas fa-lock"
                variant="outlined"
                class="email-disabled"
              ></v-text-field>
            </div>
            <div class="form-group mb-4">
              <span class="form-label">{{ t('passwords') }}</span>
              <v-text-field
                v-model="password"
                bx-attr="password"
                :append-inner-icon="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="showPassword ? 'text' : 'password'"
                variant="outlined"
                class="mt-3"
                hide-details
                :maxlength="passwordMaxLength"
                :minlength="passwordMinLength"
                @click:append-inner="showPassword = !showPassword"
              ></v-text-field>
            </div>
            <div v-if="password !== ''" class="form-group mb-4">
              <password-strength-component
                :password="password"
                @password-is-valid="updatePasswordIsValid"
              />
            </div>
            <div class="form-group">
              <span class="form-label">{{ t('i18n_repeat_password') }} </span>
              <v-text-field
                v-model="repeatPassword"
                bx-attr="repeat-password"
                :append-inner-icon="showRepeatPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="showRepeatPassword ? 'text' : 'password'"
                variant="outlined"
                class="mt-3 error-icon-label"
                :rules="[nonEmptyRule, repeatPasswordRule]"
                :maxlength="passwordMaxLength"
                :minlength="passwordMinLength"
                @click:append-inner="showRepeatPassword = !showRepeatPassword"
              >
                <template #details="{ isValid }">
                  <v-icon
                    v-if="!isValid.value && isValid.value != null"
                    class="error-icon"
                    icon="fa fa-circle-exclamation"
                  ></v-icon>
                </template>
              </v-text-field>
            </div>
            <div class="form-group">
              <v-checkbox
                v-model="termsOfService"
                hide-details
                bx-attr="terms-of-service"
                class="checkbox"
              >
                <template #label>
                  <span class="policy-text">
                    {{ t('i_agree_to_the') }}&nbsp;
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      class="arly-link"
                      href="https://grow.bellxcel.org/terms-of-service-partner"
                    >
                      {{ t('terms_of_service') }}
                    </a>
                  </span>
                </template>
              </v-checkbox>
              <v-checkbox
                v-model="privacyPolicy"
                class="checkbox"
                hide-details
                bx-attr="privacy-policy"
              >
                <template #label>
                  <span class="policy-text">
                    {{ t('i_agree_to_the') }}&nbsp;
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      class="arly-link"
                      :href="PRIVACY_POLICY_URL"
                      >{{ t('privacy_policy') }}
                    </a>
                  </span>
                </template>
              </v-checkbox>
            </div>
            <v-btn
              :disabled="disableRegisterButton"
              class="mt-4"
              :class="{ 'enabled-button': !disableRegisterButton }"
              rounded="xl"
              size="x-large"
              bx-attr="get-started"
              type="submit"
              >{{ t('get_started') }}</v-btn
            >
          </v-form>
        </div>

        <div class="links-container">
          <div class="links-container__section-top">
            <p class="links-container__section-text">
              {{ t('brought_to_you_by') }}
              <a
                class="links-container__section-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bellxcel.org/"
                >BellXcel
              </a>
            </p>
            <p class="links-container__section-text">{{ t('power_your_purpose') }}&trade;</p>
          </div>
          <hr />
          <div class="links-container__section-bottom">
            <p class="links-container__section-text">
              &copy; 2023, The {{ t('bell_fundation') }} , Inc. <br />
              {{ t('all_rights_reserved') }}
            </p>

            <a
              class="links-container__section-link"
              target="_blank"
              rel="noopener noreferrer"
              :href="PRIVACY_POLICY_URL"
            >
              {{ t('privacy_policy') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-else style="top: 50%; left: 50%; height: 100%; position: absolute">
      <v-icon
        density="compact"
        size="x-large"
        color="black"
        icon="fa-solid fa-spinner fa-spin"
      ></v-icon>
    </div>
  </div>
  <ToastComponent
    :show="showToast"
    :custom-props="{ message: toastMessage, icon: IconType.warning, title: '' }"
    :snackbar-props="{ color: toastType, timeout: '5000' }"
    @close-snackbar="() => (showToast = false)"
  />
</template>
<script setup lang="ts">
import type IEmailToken from '@/models/EmailToken'
import { ref, onBeforeMount, computed, type Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { validateEmailToken, registerUserPassword } from '@/services/UserService'
import {
  useI18n,
  PasswordStrengthComponent,
  IconType,
  MessageType,
  ToastComponent,
  vuetify
} from 'arly-common'
import { PRIVACY_POLICY_URL } from '@/constants/urls'
import headerLogo from '@/assets/images/arly/full-panda.png'
import jwt_decode from 'jwt-decode'
import { passwordMaxLength, passwordMinLength } from '@/constants/passwordLength'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const isLoading = ref(false)
// Validate token in beforeMount
onBeforeMount(async () => {
  const queryToken = route.query.token
  // No token, redirect to home
  if (!queryToken) {
    router.push({ name: 'home' })
    return
  }
  isLoading.value = true
  try {
    token.value = decodeEmailToken(queryToken as string)
    email.value = token.value.email
  } catch (error) {
    router.push({ name: 'home' })
  } finally {
    isLoading.value = false
  }

  try {
    const { firstName } = await validateEmailToken(queryToken as string)
    userName.value = firstName
  } catch (error) {
    showToast.value = true
    toastType.value = MessageType.warning
    toastMessage.value = t('i18n_registration_validation_token_error')
  }
})

const userName = ref('')
const email = ref('')
const password = ref('')
const showPassword = ref(false)
const repeatPassword = ref('')
const showRepeatPassword = ref(false)
const privacyPolicy = ref(false)
const termsOfService = ref(false)
const refVForm = ref(vuetify.vuetifyLibComponents.VForm)
const passwordIsValid = ref(false)
const token = ref({} as IEmailToken)
const showToast = ref(false)
const toastMessage = ref('')
const toastType = ref(MessageType.info)

const onSubmit = (): void => {
  refVForm.value?.validate().then(async ({ valid: isValid }: { valid: boolean }): Promise<void> => {
    if (isValid && passwordIsValid) await register()
  })
}

const disableRegisterButton = computed(() => {
  return (
    !privacyPolicy.value ||
    !termsOfService.value ||
    !refVForm.value?.isValid ||
    !passwordIsValid.value
  )
})

const repeatPasswordRule = (value: string): boolean | string => {
  return value === password.value || t('i18n_passwords_do_not_match')
}

const nonEmptyRule = (value: string): boolean | string => {
  return !!value || t('i18n_field_is_required')
}

const updatePasswordIsValid = (valid: Ref<boolean>): void => {
  passwordIsValid.value = valid.value
}

const register = async (): Promise<void> => {
  try {
    await registerUserPassword(route.query.token as string, password.value)
    router.push({ name: 'home' })
  } catch (error) {
    showToast.value = true
    toastType.value = MessageType.error
    toastMessage.value = t('i18n_registration_fails')
  }
}

const decodeEmailToken = (token: string): IEmailToken => {
  const decodedToken: IEmailToken = jwt_decode(token)
  return decodedToken
}
</script>
<style lang="scss" scoped>
.register-wrapper {
  margin: 0;
  padding: 0;
  font-weight: normal;
  background-image: url('@/assets/images/arly/background-1.png');
  background-repeat: repeat !important;
  background-size: 100% 100% !important;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.arly-logo {
  position: absolute;
  left: 2.12%;
  right: 30.83%;
  top: 0.64%;
  bottom: 23.16%;
  width: 86.91px;
  height: 72.96px;
}

.form-container {
  height: 100% !important;
  padding: 48px;
  max-width: 29.625rem;
  background: $white-color;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 100%;
}

.top-panda {
  width: 169px;
  display: block;
  margin: 0 auto;
  max-width: 169px;
}

.hi-label {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  text-align: center;
}

.welcome-label {
  font-size: 30px;
  text-align: center;
  margin-bottom: 5px !important;
  font-weight: 800;
  font-family: $Montserrat;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: $primary-gray;
  display: block;
  margin-top: 11px;
  width: 102%;
}

.complete-account {
  font-family: $Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: $primary-gray;
  display: block;
  margin-top: 11px;
  width: 102%;
  font-size: 12px;
}

.form-group {
  font-size: 16px;
}

.form-label {
  font-weight: 700;
}

.arly-link {
  font-weight: 600;
  color: $blue-700;
}

.enabled-button {
  background-color: $primary-green;
  color: $white-color;
}

.policy-text {
  color: $policy-text;
}

.checkbox {
  color: $primary-green;
}

.error-icon {
  margin-right: 10px;
  height: 1rem;
}

.error-icon-label.v-text-field :deep(.v-input__details) {
  display: flex;
  justify-content: center;
  justify-self: flex-start;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: 10px;
  padding-left: 0;
  font-weight: 600;
  line-height: $medium-text;
}

.email-disabled.v-text-field :deep(.v-input__control) {
  background: $neutral-300;
}

.links-container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;
  max-width: 29.625rem;

  &__section-link {
    color: $primary-green;
    font-size: $small-text;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }

  &__section-text {
    color: $neutral-700;
    font-size: $small-text;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__section-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1em;
  }

  &__section-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
  }
}
</style>
