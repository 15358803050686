import { unref } from 'vue'
import { useLDFlag } from 'launchdarkly-vue-client-sdk'
import { watchEffectOnceAsync } from '@/utils/watcher'
import { useLDReady } from 'launchdarkly-vue-client-sdk'

/**
 * Vue Router Guard.
 *
 * Provides a way to access flag values in the vue-router guards.
 * [per-route-guard](https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard)
 * Usage: `beforeEnter: launchDarklyGuard(flagKey, callback, defaultValue),`
 *
 * Custom logic can be apply using the callback parameter, when this parameter is passed it will be
 * in charge of the guard logic. The 'callback' function will hold the resulted flag and the injected
 * Vue Router params, check: [NavigationGuard](https://router.vuejs.org/api/interfaces/NavigationGuard.html)
 */
export default function launchDarklyGuard(
  flagKey: string,
  callback?: ((arg0: boolean, args: unknown) => void) | null,
  defaultFlagValue?: boolean
): (args: unknown) => Promise<boolean | void> {
  return async (...args) => {
    const ldReady = useLDReady()
    const routeFlag = useLDFlag(flagKey, defaultFlagValue)
    const fn = (): boolean | void => {
      if (typeof routeFlag.value !== 'boolean' && typeof callback !== 'function') {
        console.warn(
          `LaunchDarkly guard warning: Be careful, flag key '${flagKey}' does not returns boolean value (${routeFlag.value}), therefore it can lead to unexpected results.`
        )
      }

      // If a callback was passed we can run the custom logic here
      if (typeof callback === 'function') {
        return callback(routeFlag.value, ...args)
      }

      // Otherwise we just return the flag value so the guard can continue
      return routeFlag.value
    }

    if (!unref(ldReady)) {
      await watchEffectOnceAsync(() => unref(ldReady))
    }

    return fn()
  }
}
