import { watchEffect } from 'vue'

/**
 * Run watchEffect until the watcher returns true, then stop the watch.
 * Once it returns true, the promise will resolve.
 * [Reference](https://github.com/auth0/auth0-vue/blob/main/src/utils.ts)
 */
export function watchEffectOnceAsync<T>(watcher: () => T): Promise<any> {
  return new Promise<void>((resolve) => {
    watchEffectOnce(watcher, resolve)
  })
}

/**
 * Run watchEffect until the watcher returns true, then stop the watch.
 * Once it returns true, it will call the provided function.
 * [Reference](https://github.com/auth0/auth0-vue/blob/main/src/utils.ts)
 */
export function watchEffectOnce<T>(
  watcher: () => T,
  fn: (value: void | PromiseLike<void>) => void
): any {
  const stopWatch = watchEffect(() => {
    if (watcher()) {
      fn()
      stopWatch()
    }
  })
}
