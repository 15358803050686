<template>
  <div
    v-if="isAuthenticated && !isLoading && !isLoadingRoute && !isLoadingUser && userDataVal"
    class="login-page-wrapper"
  >
    <div class="arly-logo">
      <img src="@/assets/images/arly/Arly_Logo_Green.png" alt="Arly logo" />
    </div>

    <br />

    <button v-if="!isAuthenticated" @click="login">Log in</button>
    <button v-if="isAuthenticated" @click="logout">Log out</button>
  </div>

  <div v-else style="top: 50%; left: 50%; height: 100%; position: absolute">
    <v-icon
      density="compact"
      size="x-large"
      color="black"
      icon="fa-solid fa-spinner fa-spin"
    ></v-icon>
  </div>
</template>

<script setup lang="ts">
import type IOktaToken from '@/models/OktaToken'
import { useAuth0, type Auth0VueClient } from '@auth0/auth0-vue'
import { onBeforeMount, ref } from 'vue'
import jwt_decode from 'jwt-decode'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

const auth0: Auth0VueClient = useAuth0()

const isAuthenticated = auth0.isAuthenticated
const isLoading = auth0.isLoading

const isLoadingRoute = ref(true)

const userStore = useUserStore()
const { isLoadingUser, userDataVal } = storeToRefs(userStore)

async function gotoAdmin(): Promise<void> {
  const token: string = await auth0.getAccessTokenSilently()
  if (token) {
    // Get the access token generated by the Arly API
    const decodedToken: IOktaToken = jwt_decode(token)

    const { roleId, isBxPortal } = decodedToken

    // Only admin users have access, as we're using the same login page for
    // Guardians/Admins in Arly. We need to check the user's permissions
    if (isBxPortal) {
      const userRoleId = +roleId
      const adminURL = `${import.meta.env.VITE_APP_BASE_ADMIN_REDIRECT_URL}`
      const endPath = `?authRedirect=true&type=login`
      if ([4, 5, 6, 7].includes(userRoleId)) {
        window.location.href = adminURL + 'partnerhome' + endPath
      } else if ([1, 2].includes(userRoleId)) {
        const redirectURL = adminURL + 'home' + endPath
        window.location.href = redirectURL
      }
    } else {
      logout()
    }
  }
}

onBeforeMount(async () => {
  // add redirect to other pages
  await userStore.fetchUserData()
  if (isAuthenticated && !isLoadingUser.value && userDataVal.value) {
    gotoAdmin()
  }
})

function login(): void {
  auth0.loginWithRedirect()
}

function logout(): void {
  auth0.logout({
    logoutParams: {
      returnTo: window.location.origin
    }
  })
}
</script>

<style scoped>
.login-page-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url('../assets/images/arly/background-1.png');
  background-size: 100% 100% !important;
  background-position: center;
  font-family: 'Montserrat';
  font-size: 22px;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.arly-logo img {
  position: absolute;
  left: 2.12%;
  right: 30.83%;
  top: 0.64%;
  bottom: 23.16%;
  width: 86.91px;
  height: 72.96px;
}
</style>
