<template>
  <v-col
    cols="12"
    sm="12"
    md="4"
    offset-md="1"
    lg="3"
    offsset-lg="3"
    xl="3"
    offset-xl="3"
    xxl="3"
    :style="profilePicture"
    class="profile-picture-container pa-0"
  >
    <div
      class="profile-picture-container__profile-picture"
      :class="'profile-picture-container__profile-picture--img-content'"
    ></div>

    <v-btn
      v-if="props.editMode"
      class="profile-picture-container__upload-link-container rounded-0 d-flex"
      @click="toggleModal(true)"
      >{{ t('i18n_update_profile_picture') }}</v-btn
    >

    <UserProfilePhotoComponent :dialog="dialogOpen" @toggle-modal="toggleModal" />
  </v-col>
</template>

<script setup lang="ts">
import { useI18n } from 'arly-common'
import UserProfilePhotoComponent from '@/components/user-profile/UserProfilePhotoComponent.vue'
import { ref, computed } from 'vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import PlaceholderImage from '@/assets/images/icons/user-avatar.svg'

const props = defineProps<{
  editMode?: boolean
}>()

const userStore = useUserStore()
const { userPictureVal, userDataVal } = storeToRefs(userStore)
const { t } = useI18n()
const dialogOpen = ref(false)

function toggleModal(toggle: boolean): void {
  dialogOpen.value = toggle
}

const profilePicture = computed(() => {
  const parsedBase64 =
    userPictureVal.value && userPictureVal.value.contentType && userPictureVal.value.contentBytes
      ? `data:${userPictureVal.value.contentType};base64,${userPictureVal.value.contentBytes}`
      : userDataVal.value?.profilePicture[0]?.imageUrl

  const profileImg = parsedBase64 || PlaceholderImage

  return `
      background-image: url('${profileImg}');
      background-repeat: no-repeat;
      background-size: cover;`
})
</script>

<style lang="scss" scoped src="./UserProfilePictureLoader.scss"></style>
