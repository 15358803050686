<template>
  <div class="profile-component-container">
    <UserProfileFormComponent
      v-if="editMode"
      @show-password-reset="showPasswordResetDialog"
      @set-edit-mode="setEditMode"
      @handle-toast-message="handleToastMessage"
    />
    <UserProfileDataComponent
      v-else
      @show-password-reset="showPasswordResetDialog"
      @set-edit-mode="setEditMode"
    />
    <PasswordResetModalComponent :dialog="dialog" @hide-password-reset="hidePasswordResetDialog" />

    <ToastComponent
      v-if="toastCustomProps"
      :custom-props="{
        message: toastCustomProps.message,
        icon: toastCustomProps.icon,
        title: toastCustomProps.title
      }"
      :snackbar-props="{ color: toastColor, timeout: '5000' }"
      :show="isToastVisible"
      @close-snackbar="handleCloseSnackbar"
    ></ToastComponent>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, provide } from 'vue'
import UserProfileFormComponent from '@/components/user-profile/UserProfileFormComponent.vue'
import UserProfileDataComponent from '@/components/user-profile/UserProfileDataComponent.vue'
import PasswordResetModalComponent from '@/components/password-reset-modal/PasswordResetModalComponent.vue'
import { useUserStore } from '@/stores/UserStore'
import { ToastComponent, type ICustomSnackbarProps, MessageType } from 'arly-common'
import type { IUserProfileToast } from '@/models/UserProfileToast'

const editMode = ref(false)
const dialog = ref(false)
const userStore = useUserStore()
const toastCustomProps = ref<ICustomSnackbarProps>()
const toastColor = ref(MessageType.success)

const isToastVisible = ref(false)

onBeforeMount(() => {
  userStore.fetchUserData()
})

function showPasswordResetDialog(): void {
  dialog.value = true
}

function setEditMode(active: boolean): void {
  editMode.value = active
}

function hidePasswordResetDialog(): void {
  dialog.value = false
}

function handleToastMessage({ toastProps, toastColorValue }: IUserProfileToast): void {
  toastCustomProps.value = toastProps
  toastColor.value = toastColorValue
  isToastVisible.value = true
}

function handleCloseSnackbar(): void {
  isToastVisible.value = false
}

provide('handleToastMessage', handleToastMessage)
</script>

<style lang="scss" scoped>
.profile-component-container {
  min-height: calc(100vh - 5.5rem);
}
</style>
