<template>
  <v-dialog
    v-model="dialog"
    class="password-reset-dialog"
    :class="{ w100: mobile }"
    :fullscreen="mobile"
  >
    <v-card class="password-reset-dialog__card">
      <v-card-title
        class="password-reset-dialog__heading-section"
        :class="{ 'no-margin-helper': mobile }"
      >
        <v-icon
          v-if="!mobile"
          icon="fa-solid fa-xmark"
          class="pt-3 float-right mr-4"
          @click="closeDialog"
        />
        <v-row>
          <v-col align="center" cols="12">
            <h2 class="pt-5" :class="{ 'password-reset-dialog__header': !mobile }">
              <template v-if="passwordUpdated">
                {{ t('i18n_password_updated') }}
              </template>
              <template v-else>
                {{ t('i18n_change_password') }}
              </template>
            </h2>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <template v-if="passwordUpdated">
          <v-row align="center" justify="center">
            <v-col cols="12 pb-10 mb-10">
              <v-alert type="success" variant="tonal">
                {{ t('i18n_password_successfully_updated') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <v-btn
                block
                variant="outlined"
                rounded="xl"
                class="password-reset-dialog__cancel-button"
                @click="closeDialog"
                >{{ t('i18n_close') }}</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="12">
              <v-img :src="headerLogo" width="93" class="mx-auto" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center">{{ t('i18n_please_enter_new_password') }}</v-col>
          </v-row>
          <v-row v-if="errorUpdatingPassword" justify="center">
            <v-col :cols="mobile ? 12 : 8">
              <v-alert type="error" variant="tonal">
                {{ t('i18n_oops_error') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col :cols="mobile ? 12 : 8">
              <v-text-field
                v-model="currentPassword"
                :append-inner-icon="showCurrentPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="showCurrentPassword ? 'text' : 'password'"
                :label="t('i18n_current_password')"
                variant="outlined"
                class="mt-3"
                hide-details
                @click:append-inner="showCurrentPassword = !showCurrentPassword"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col :cols="mobile ? 12 : 8">
              <v-text-field
                v-model="newPassword"
                :append-inner-icon="showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="showNewPassword ? 'text' : 'password'"
                :label="t('i18n_new_password')"
                variant="outlined"
                class="mt-3"
                hide-details
                :maxlength="passwordMaxLength"
                :minlength="passwordMinLength"
                @click:append-inner="showNewPassword = !showNewPassword"
                @update:model-value="repeatPasswordValidate()"
              />
            </v-col>
          </v-row>
          <v-row v-if="newPassword !== ''" justify="center">
            <v-col :cols="mobile ? 12 : 8">
              <password-strength-component
                :current-password="currentPassword"
                :password="newPassword"
                @password-is-valid="updateNewPasswordIsValid"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col :cols="mobile ? 12 : 8">
              <v-text-field
                ref="confirmPasswordInput"
                v-model="repeatNewPassword"
                :append-inner-icon="showRepeatNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                :type="showRepeatNewPassword ? 'text' : 'password'"
                :label="t('i18n_repeat_password')"
                variant="outlined"
                class="mt-3 error-icon-label"
                :rules="[repeatPasswordRule]"
                :maxlength="passwordMaxLength"
                :minlength="passwordMinLength"
                @click:append-inner="showRepeatNewPassword = !showRepeatNewPassword"
              >
                <template #details="{ isValid }">
                  <v-icon
                    v-if="!isValid.value && isValid.value != null"
                    class="error-icon"
                    icon="fa fa-circle-exclamation"
                  ></v-icon> </template
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions v-if="!passwordUpdated" class="mb-15 mt-10">
        <v-row justify="center">
          <v-col :cols="mobile ? 12 : 3">
            <v-btn
              block
              variant="outlined"
              rounded="xl"
              class="password-reset-dialog__cancel-button"
              @click="closeDialog"
              >{{ t('i18n_cancel') }}</v-btn
            >
          </v-col>
          <v-col :cols="mobile ? 12 : 4">
            <v-btn
              block
              variant="outlined"
              rounded="xl"
              class="password-reset-dialog__save-button"
              :disabled="disableRegisterButton"
              :loading="loading"
              @click="updateUserPassword"
              >{{ t('i18n_update_password') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed, toRefs, ref, type Ref } from 'vue'
import { useI18n, PasswordStrengthComponent, vuetify } from 'arly-common'
import headerLogo from '@/assets/images/changePassword/header.svg'
import { updatePassword } from '@/services/UserService'
import { passwordMaxLength, passwordMinLength } from '@/constants/passwordLength'
import type { VTextField } from 'vuetify/components'

const { t } = useI18n()
const props = defineProps<{
  dialog: boolean
}>()

const emit = defineEmits<{
  (e: 'hidePasswordReset'): void
}>()

const { mobile } = vuetify.vuetifyLib.useDisplay()
const { dialog } = toRefs(props)
const loading = ref(false)
const errorUpdatingPassword = ref(false)
const passwordUpdated = ref(false)
const currentPassword = ref('')
const showCurrentPassword = ref(false)
const newPassword = ref('')
const showNewPassword = ref(false)
const repeatNewPassword = ref('')
const showRepeatNewPassword = ref(false)
const newPasswordIsValid = ref(false)
const confirmPasswordInput = ref<vuetify.vuetifyLibComponents.VTextField>()

function closeDialog(): void {
  emit('hidePasswordReset')
}

function updateNewPasswordIsValid(valid: Ref<boolean>): void {
  newPasswordIsValid.value = valid.value
}

const repeatPasswordRule = (value: string): boolean | string => {
  return value === newPassword.value || t('i18n_passwords_do_not_match')
}
const repeatPasswordValidate = (): void => {
  if (confirmPasswordInput.value?.dirty) {
    confirmPasswordInput.value?.validate()
  }
}

const disableRegisterButton = computed(() => {
  return (
    !newPasswordIsValid.value ||
    newPassword.value !== repeatNewPassword.value ||
    currentPassword.value === newPassword.value ||
    currentPassword.value === ''
  )
})

async function updateUserPassword(): Promise<void> {
  loading.value = true
  try {
    await updatePassword({
      currentPassword: currentPassword.value,
      password: newPassword.value,
      confirmPassword: repeatNewPassword.value
    })
    errorUpdatingPassword.value = false
    passwordUpdated.value = true
    currentPassword.value = ''
    newPassword.value = ''
    repeatNewPassword.value = ''
  } catch (error) {
    errorUpdatingPassword.value = true
  }
  loading.value = false
}
</script>
<style scoped lang="scss">
.password-reset-dialog {
  max-width: 40rem;
  &__header {
    margin-left: 5rem;
  }

  &__save-button {
    background-color: $primary-orange;
    color: $primary-button;
    border: 0;
    font-size: 0.8rem;
  }

  &__cancel-button {
    color: $smallbtn-border;
    border-color: $smallbtn-border;
    font-size: 0.8rem;
  }

  &__heading-section {
    width: 100%;
    min-height: 4.75rem;
    display: block;
    background: $login-bg;
    color: $white-color;
    font-size: 1rem;
    border-bottom-left-radius: 50% 40%;
    border-bottom-right-radius: 50% 40%;
    padding: 0 0;
    margin-bottom: 3rem;
  }

  .error-icon-label.v-text-field :deep(.v-input__details) {
    display: flex;
    justify-content: center;
    justify-self: flex-start;
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 10px;
    padding-left: 0;
    font-weight: 600;
    line-height: $medium-text;
  }
  .error-icon {
    margin-right: 10px;
    height: 1rem;
  }
  .no-margin-helper {
    margin: 0 !important;
  }
}
</style>
