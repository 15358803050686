<template>
  <header>
    <nav><HeaderComponent :page-title="'Playground'" /></nav>
  </header>

  <div class="playground-wrapper">
    <div class="playground-content">
      <h3>This is the magical playground of the developers</h3>
      <h5>Welcome to this mysterious land full of wonders</h5>

      <div class="ld-demo">
        <div v-if="featureFlagContext">Flags for context: {{ featureFlagContext.key }}</div>
        <ul>
          <li v-for="(flag, index) in flags" :key="index">{{ index }}: {{ flag }}</li>
        </ul>
      </div>
    </div>
  </div>

  <FooterComponent />
</template>

<script setup lang="ts">
import { HeaderComponent } from 'arly-common'
import FooterComponent from '@/components/footer/FooterComponent.vue'
import { onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useLDClient } from 'launchdarkly-vue-client-sdk'
import { useUserStore } from '@/stores/UserStore'
let flags = ref()
let featureFlagContext = ref()
const userStore = useUserStore()
const { userDataVal } = storeToRefs(userStore)

const ldClient = useLDClient()

onBeforeMount(async () => {
  await userStore.fetchUserData()

  // Use identify to change context and get flags for new context
  await ldClient.identify({
    kind: 'Users',
    key: userDataVal.value?.id.toString(),
    name: userDataVal.value?.email,
    email: userDataVal.value?.email
  })
  // Use getContext to get flags for current context
  featureFlagContext.value = ldClient.getContext()
  // User allFlags to get all flags for current context
  flags.value = ldClient.allFlags()
})
</script>
<style lang="scss" scoped>
.playground-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url('../assets/images/arly/background-1.png');
  background-size: 100% 100% !important;
  background-position: center;
  font-family: 'Montserrat';
  font-size: 22px;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.playground-content {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ld-demo {
  margin-top: 2rem;
}
</style>
