{
  "all_rights_reserved": "All rights reserved.",
  "create_your_account": "Create your account",
  "email": "Email",
  "get_started": "Get Started",
  "greeting": "Hi",
  "i18n_account": "Account",
  "i18n_cancel": "Cancel",
  "i18n_cancel_button": "Cancel",
  "i18n_change_password": "Change password",
  "i18n_close": "Close",
  "i18n_close_dialog": "Close Dialog",
  "i18n_current_password": "Current password",
  "i18n_edit_profile": "Edit Profile",
  "i18n_email_address": "Email Address",
  "i18n_field_is_required": "This field is required",
  "i18n_first_name": "First Name",
  "i18n_invalid_email": "Invalid email",
  "i18n_last_name": "Last Name",
  "i18n_logout": "Logout",
  "i18n_new_password": "New password",
  "i18n_new_password_not_same_as_current_password": "New password is not the same as the current password",
  "i18n_not_found": "Not Found",
  "i18n_only_alphabetic_characters": "Sorry, please use only alphabetic characters. Hyphens and apostrophes are also allowed",
  "i18n_oops_error": "Oops, an error occurred. Please try again.",
  "i18n_password": "Password",
  "i18n_password_successfully_updated": "Password successfully updated",
  "i18n_password_updated": "Password updated",
  "i18n_phone_number": "Phone Number",
  "i18n_please_enter_new_password": "Please enter a new password below.",
  "i18n_pooppover_action": "Delete Profile Photo",
  "i18n_poppover_title": "Are you sure you want to delete your photo?",
  "i18n_profile": "Profile",
  "i18n_profile_photo_description": "Take or upload your profile photo. Photo must be in either JPG or PNG format and must not exceed 1MB in size.",
  "i18n_profile_photo_title": "Add Photo",
  "i18n_profile_picture": "Profile Picture",
  "i18n_profile_picture_camera_error": "Error uploading picture from camera",
  "i18n_profile_picture_deleted": "Profile picture deleted successfully",
  "i18n_profile_picture_error": "Error uploading the file",
  "i18n_profile_picture_error_saving": "Error saving the user picture",
  "i18n_profile_picture_not_supported_error": "We had trouble uploading your photo. Please ensure that it is either JPG or PNG, and does not exceed 1MB in size.",
  "i18n_profile_picture_uploaded": "Profile picture uploaded successfully",
  "i18n_repeat_password": "Confirm Password",
  "i18n_save_button": "Save",
  "i18n_save_settings": "Save settings",
  "i18n_update_password": "Update password",
  "i18n_update_profile_picture": "Update profile picture",
  "i18n_upload_button": "Upload",
  "i18n_upload_profile_picture": "Upload Profile Picture",
  "i18n_use_camera_button": "use camera",
  "i18n_user_profile_saved": "User updated successfully",
  "i18n_user_profile_saving_error": "Error saving user profile",
  "i18n_registration_validation_token_error": "Error validating registration token",
  "i18n_registration_fails": "Error registering the user",
  "i_agree_to_the": "I agree to the",
  "medium": "Medium",
  "message": "Welcome to my app.",
  "passwords": "Password",
  "i18n_passwords_do_not_match": "The retyped password doesn't match the new password.",
  "privacy_policy": "Privacy Policy",
  "register_tooltip": "After you create your account, you can update your email address from your profile.",
  "strong": "Strong",
  "terms_of_service": "Terms of Service",
  "weak": "Weak",
  "welcome_to_arly": "Welcome to Arly",
  "brought_to_you_by": "Brought to you by",
  "power_your_purpose": "Power your purpose",
  "bell_fundation": "B.E.L.L. Foundation"
}
